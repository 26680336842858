import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import LocaleContext from '../context/LocaleContext'
import PageContext from '../context/PageContext'
import { Locale, localisePath } from '../domain/locale'
import { PRODUCTS_PATH } from '../domain/path'
import { ChevronRightIcon } from '@heroicons/react/solid'
import ReactCountryFlag from 'react-country-flag'
import Layout from '../components/layout/Layout'
import Seo from '../components/layout/Seo'

function ProductsPage() {
    const { setLocale } = useContext(LocaleContext)

    useEffect(() => {
        setLocale(Locale.Global)
    }, [])

    return (
        <PageContext.Provider
            value={{
                basepath: PRODUCTS_PATH,
                locale: Locale.Global,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Products"
                    titleCompany="Telobag Official"
                    description="Telobag makes cassava-starch bags that are organic and thus 100% biodegradable and compostable"
                    relativeUrl={localisePath(PRODUCTS_PATH, Locale.Global)}
                />

                {/* Title and short horizontal rule underneath */}
                <h1 className="font-bold text-3xl md:text-5xl mb-3 text-primary">
                    Products
                </h1>
                <hr className="border-b border-gainsboro w-10" />

                {/* Product categories displayed as cards */}
                <div className="mt-6">
                    Please visit our partner stores
                    <br />
                    <div className="flex flex-col">
                        <div className="my-2">
                            <a
                                href="https://www.telobag.sg/online-store"
                                className="mt-4 inline-flex items-center bg-gray-200 hover:bg-gray-400 px-4 py-3 rounded-lg text-gray-800 focus:outline-none"
                            >
                                <div className="mr-3">
                                    <ReactCountryFlag
                                        countryCode="sg"
                                        style={{
                                            width: '1.5em',
                                            height: '1.5em',
                                        }}
                                        svg
                                    />
                                </div>
                                Telobag SG
                                <ChevronRightIcon className="h-6 w-6 ml-2" />
                            </a>
                        </div>
                        <div className="my-2">
                            <Link
                                to={localisePath(PRODUCTS_PATH, Locale.AU)}
                                className="mt-4 inline-flex items-center bg-gray-200 hover:bg-gray-400 px-4 py-3 rounded-lg text-gray-800 focus:outline-none"
                            >
                                <div className="mr-3">
                                    <ReactCountryFlag
                                        countryCode="au"
                                        style={{
                                            width: '1.5em',
                                            height: '1.5em',
                                        }}
                                        svg
                                    />
                                </div>
                                Telobag AU
                                <ChevronRightIcon className="h-6 w-6 ml-2" />
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout>
        </PageContext.Provider>
    )
}

export default ProductsPage
